import React from 'react'
import { ContactsStyle } from './Contacts.styled'
import {BiTimeFive} from 'react-icons/bi'
import {BiCalendarAlt} from 'react-icons/bi'
import {BiIdCard} from 'react-icons/bi'
import { useTranslation } from 'react-i18next'

export const Contacts = () => {
    const {t} = useTranslation()
  return (
    <ContactsStyle>
        <div className="contactsMain">
            <div className="contactsItems">
                <div className="timeItem">
                        <span className='contactsIcon'>
                        <BiTimeFive fill='#fff' size='30px'/>
                        </span>
                    <div className='timeList'>
                        <span className='timeTitle'>{t("contacts")}:</span>
                        <span>09:00 - 18:00</span>
                    </div>
                </div>
                <div className="timeItem">
                    <span className='contactsIcon'>
                        <BiCalendarAlt fill='#fff' size='30px'/>
                    </span>
                    <div className='timeList'>
                        <span className='timeTitle'>{t("contacts2")}:</span>
                        <span>{t("contactsdays")} - {t("contactsdays2")}</span>
                    </div>
                </div>
                <div className="timeItem">
                    <span className='contactsIcon'>
                        <BiIdCard fill='#fff' size='30px'/>
                    </span>
                    <div className='timeList'>
                        <span className='timeTitle'>{t("contacts3")}:</span>
                        <span>76-17-99, 76-06-16</span>
                    </div>
                </div>
            </div>
        </div>
    </ContactsStyle>
  )
}
