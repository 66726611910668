import styled from "styled-components";

export const NewsStyles = styled.section`
  h1 {
    font-size: 3.5rem;
    letter-spacing: 5px;
    color: #0045a0;
    margin-bottom: 20px;
  }
  .newsItemInside {
    display: flex;
    margin: 25px 0px;
  }
  .imgItem img {
    cursor: pointer;
    width: 350px;
  }
  .imgItem {
    cursor: pointer;
    width: 350px;
    transition: all 0.2s ease-in;
    &:hover {
      box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.75);
    }
  }
  .textItem {
    margin-left: 50px;
    h3 {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 20px;
    }
    p {
      font-size: 1.1rem;
      &:last-child {
        margin-top: 10px;
        font-weight: 300;
        color: rgba(51, 51, 51, 0.5);
      }
    }
  }
  .modalItem {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modalContent {
    width: 70%;
    max-width: 1000px;
    padding: 5px 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    /* background-color: rgb(201, 201, 201); */
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    background-color: #fff;
  }

  .modalItem.active {
    opacity: 0;
    visibility: hidden;

    .modalContent {
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, -30%);
      transition-delay: 0.2s;
    }

    &.show {
      opacity: 1;
      visibility: visible;

      .modalContent {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -50%);
      }
    }
  }
  .modal-cross {
    display: flex;
    justify-content: end;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .downloadBtn {
    display: flex;
    justify-content: end;
    cursor: pointer;
    margin-top: 5px;
  }

  hr {
    border: 0;
    height: 1px;
    background: #333;
    background: rgba(51, 51, 51, 0.17);
    margin-bottom: 20px;
  }
  @media (max-width: 1440px) {
    .modalContent {
      max-width: 800px;
    }
  }
  @media (max-width: 1024px) {
    .modalContent {
      max-width: 700px;
    }
  }
  @media (max-width: 425px) {
    .modalContent {
      max-width: none;
      padding: 0px 0px;
    }
    .modalContent img {
      max-width: 300px;
      min-height: 240px;
    }
  }
  @media (max-width: 375px) {
    .modalContent img {
      max-width: 260px;
      min-height: 220px;
    }
    .modal-cross {
      margin-bottom: 10px;
    }
    .downloadBtn {
      margin-top: 5px;
    }
  }
  @media (max-width: 320px) {
    .modalContent img {
      max-width: 220px;
      min-height: 180px;
    }
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 2rem;
      letter-spacing: 3px;
      margin-bottom: 15px;
    }
    .textItem {
      margin-left: 30px;
      h3 {
        font-size: 1.5rem;
        margin-bottom: 15px;
      }
      p {
        font-size: 1rem;
      }
    }
  }
`;
