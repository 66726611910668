import React from 'react'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { NewsContent } from '../NewsComponents/NewsContent'
import { Container } from '../styles/Container.styled'

export const News = () => {
  return (
    <>
    <Header/>
    <Container>
        <NewsContent/>
    </Container>
    <Footer/>
    </>
  )
}
