import {useState} from "react";
import { NewsStyles } from "./NewsContent.styled";
import img from "../../img/news-img.jpg";
import imgRus from "../../img/rusConference.png";
import imgEng from "../../img/engConference.png";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import { BsDownload } from "react-icons/bs";
import { getNewsApi } from "../../service/News";
import formatDate from "../../utils/useFormatDate"
export const NewsContent = () => {
  const [openNewsId, setOpenNewsId] = useState(null);
  const { data } = getNewsApi.useGetAllNewsDataQuery();
  const { t } = useTranslation();

  const handleOpenModal = (newsId) => {
    setOpenNewsId(newsId);
  };

  const handleCloseModal = () => {
    setOpenNewsId(null);
  };

  const renderNews = () => {
    return data?.map((news) => (
      <div key={news.id} className="newsItem">
        <hr />
        <div className="newsItemInside">
          <div onClick={() => handleOpenModal(news.id)} className="imgItem">
            <img src={news.image} alt={news.title} />
          </div>
          <div className="textItem">
            <h3>{news.title}</h3>
            <p>{formatDate(news.date_created)}</p>
          </div>
        </div>
      </div>
    ));
  };

  const renderModal = () => {
    if (!openNewsId) return null;

    const selectedNews = data?.find((news) => news.id === openNewsId);
    if (!selectedNews) return null;

    return (
      <div className={`modalItem active show`}>
        <div className="modalContent">
          <div onClick={handleCloseModal} className="modal-cross">
            <RxCross2 fill="#000" size="30px" />
          </div>
          <img src={selectedNews.image} alt={selectedNews.title} />
          <div className="downloadBtn">
            <a href={selectedNews.image} download>
              <BsDownload fill="#000" size="30px" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <NewsStyles>
      <div className="NewsMain">
        <h1>{t("news")}</h1>
        <div className="newsItemContainer">
          {renderNews()}
          {renderModal()}
        </div>
      </div>
    </NewsStyles>
  );
};
