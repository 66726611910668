import styled from "styled-components";


export const AboutStyles = styled.section`
    margin: 100px 0px;
    hr{
        width: 100%;
        height: 10px;
        border: 0 none;
        margin-right: auto;
        margin-left: auto;
        margin-top: 80px;
        margin-bottom:90px;
        background-color:#555;
    }
    h1{
        font-size: 3.5rem;
        letter-spacing: 5px;
        color: #0045A0;
        margin-bottom: 20px;
    }

    p{
        font-size: 1.2rem;
        line-height: 31px;

        span{
            font-weight: 600;
        }
    }

    @media (max-width: 768px){
        margin: 50px 0px;

        h1{
        font-size: 2rem;
        margin-bottom: 15px;
    }
    p{
        font-size: 1rem;
        line-height: 25px;
    }
    }
`