import styled from "styled-components";


export const FooterMain = styled.footer`
    background-color: #6EAB3D;
    padding: 50px 0px;
    margin-top: 100px;


    .footerContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    p{
        color: #fff;
        text-align: center;
        font-size: 1.5rem;
        &:last-child{
            margin-top: 20px;
        }
        span{
            color: #0256d4;
        }
    }
    @media (max-width: 425px){
        padding: 25px 0px;
        margin-top: 50px;

            p{
            font-size: 1.2rem;
        }
    }
`