import { Link } from "react-router-dom"
import styled from "styled-components"

const ErrorStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    h1{
        font-size: 4rem;
        color: #6EAB3D;
        display: block;
    }
    p{
        font-size: 2rem;
        margin: 20px 0px;
    }
    button{
        padding: 15px;
        background-color: #6EAB3D;
        color: #fff;
        margin-top: 20px;
    }
`

export const Error = () => {
  return (
    <ErrorStyles>
        <h1>404</h1>
        <p>Sahypa tapylmady</p>
        <Link to='/'><button>Baş sahypa</button></Link>
        

    </ErrorStyles>
  )
}
