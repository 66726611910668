import React from 'react'
import { HeroStyles } from './Hero.styled'
import img from '../../img/hero-bg2.png'
import { useTranslation } from 'react-i18next'

export const Hero = () => {
  const {t} = useTranslation()
  return (
    <HeroStyles>
        <div className='HeroMain'>
            <div className='heroImage'>
                <img src={img} alt="hero-img" />
            </div>
            <div className='heroText'>
                <p className='content1'>{t("hero")} 
                </p>
            </div>
        </div>
    </HeroStyles>
  )
}
