import styled from "styled-components";


export const HeroStyles = styled.div`
    margin-bottom: 90px;
    .HeroMain{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .heroText{
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 65px;
        color: #6dab3c;
        font-weight: 600;
        text-align: justify;
        margin: 0 auto;
        max-width: 683px;
        
    }

    .heroImage{
        display: block;
        opacity: 1;
        animation: revealimg 1s ease-in forwards;
    }

    .content1 {
        display: block;
        transform: translateY(100%);
        animation: reveal 1s ease-in forwards;
    }



@keyframes reveal{
    from{
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes revealimg{
    from{
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}


    @media (max-width:1024px){
        .heroText{
        font-size: 1.7rem;
        line-height: 40px;
        margin-right: 60px;
        max-width: 500px;
        margin: 0 auto;
        }
        .heroImage img{
            width: 300px;
        }
    }
    @media (max-width: 768px){
        .heroText{
        font-size: 1.4rem;
        line-height: 40px;
        margin-right: 20px;
        max-width: 400px;
        margin: 0 auto;
        }
        .heroImage img{
            width: 200px;
        }

    }
    @media (max-width: 425px){
        .HeroMain{
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .heroText{
        font-size: 1.3rem;
        line-height: 30px;
        margin-right: 0px;
        margin-top: 20px;
        text-align: justify;
        }
        .heroImage img{
            width: 200px;
        }
    }

`