import { Header } from "../Header"
import { Container } from "../styles/Container.styled"
import { AboutContent } from "../AboutComponents/AboutContent"
import { Footer } from "../Footer"

export const About = () => {
  return (
    <>
    <Header/>
    <Container>
        <AboutContent/>
    </Container>
    <Footer/>
    </>
  )
}
