import React , { useState } from 'react'
import { ContactsStyles } from './ContactsContent.styled'
import {BiPhone} from 'react-icons/bi'
import {BiMailSend} from 'react-icons/bi'
import {BiCalculator} from 'react-icons/bi'
import {BiLocationPlus} from 'react-icons/bi'
import { useTranslation } from 'react-i18next'


export const ContactsContent = () => {
     const { t } = useTranslation();
  const [setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message, theme, section } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      theme: theme.value,
      section: section.value,
      message: message.value,
    };
    let response = await fetch("http://192.168.1.17:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    window.location.reload("5000");
    let result = await response.json();
    alert(result.status);
  };
  return (
    <ContactsStyles>
        <div className="contactsMain">
            <h1>{t("contactMain")}</h1>
            <div className="contactContainer">
                <div className="contactColoum">
                    <div className="contactNumber">
                        <div className="numberItemColoum">
                            <div className='icon'><BiPhone fill='#fff' size='30px'/></div>
                            <div className='numberText'>
                                <a href="tel:+99312761799">
                                    <span>{t("con1")}:</span>
                                    <span>76-17-99</span>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                    <div className="contactNumber">
                        <div className="numberItemColoum">
                            <div className='icon'><BiMailSend fill='#fff' size='30px'/></div>
                            <div className='numberText'>
                                <a href="mailto:otdelregcr@online.tm">
                                    <span>{t("con2")}:</span>
                                    <span>otdelregcr@online.tm</span>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                    <div className="contactNumber">
                        <div className="numberItemColoum">
                            <div className='icon'><BiCalculator fill='#fff' size='30px'/></div>
                            <div className='numberText'>
                                <span>{t("con3")}:</span>
                                <span>(+99312) 76-06-16</span>
                            </div>
                        </div>
                    </div>
                    <div className="contactNumber">
                        <div className="numberItemColoum">
                            <div className='icon'><BiLocationPlus fill='#fff' size='30px'/></div>
                            <div className='numberText'>
                                <span>{t("con4")}:</span>
                                <span>{t("con5")}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contactsInputMain">
                    <div className="inputItem">
                        <form onSubmit={handleSubmit} action="">
                            <div className="flexInput">
                                 <div>
                                    <label>{t("con6")} *</label>
                                    <input type="text" />
                                </div>
                                <div>
                                    <label> {t("con7")} *</label>
                                    <input type="text" />
                                </div>
                            </div>
                           
                            <div className='inputFull'> 
                                <label>{t("con8")}</label>
                                <input type="text" />
                            </div>
                            <div className='inputFull'>
                                <select id="section" >
                                    <option >{t("con9")}</option>
                                    <option >{t("con10")}</option>
                                    <option >{t("con11")}</option>
                                    <option >{t("con12")}</option>
                                </select>
                            </div>
                            <div className='inputFull'>
                                <label >{t("con13")}</label>
                                <textarea name="" id="" cols="30" rows="10"></textarea>
                            </div>
                            <div className='inputBtn'>
                                <button type="submit">{t("con14")}</button>
                            </div>
                            
                        </form>
                    </div>
                </div>
                
            </div>
        </div>
    </ContactsStyles>
  )
}
