
import React from "react";
import { News } from "./components/pages/News";
import { About } from "./components/pages/About";
import { Home } from "./components/pages/Home";
import { Error } from "./components/pages/Error";
import GlobalStyles from "./components/styles/Global";
import { Service } from "./components/pages/Service";
import { Contacts } from "./components/pages/Contacts";
import { SharedLayout } from "./components/pages/SharedLayout";
import {BrowserRouter, Routes, Route} from 'react-router-dom'


function App() {

  return (
    <>
    <GlobalStyles/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SharedLayout/>}>
            <Route index element={<Home/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/news' element={<News/>}/>
            <Route path='/service' element={<Service/>}/>
            <Route path='/contacts'  element={<Contacts/>}/>
            <Route path='*' element={<Error/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
    
  );
}

export default App;
