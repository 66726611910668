import styled from "styled-components";


export const ServiceStyles = styled.section`
        margin: 40px 0px;
    h1{
        font-size: 3.5rem;
        letter-spacing: 5px;
        color: #0045A0;
        margin-bottom: 20px;
    }
    .download-container{
        margin-top: 15px;
    }
    .download-btn{
        padding: 10px;
        background-color: #6dab3c;
        margin-top: 15px;
    }
    a{
        text-decoration: none;
        color: #fff;
        margin-left: 10px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover{
            color: #fff;
            box-shadow: -4px 10px 20px -6px rgba(0,0,0,0.75);
            background-color: #58c202;
        }
        span{
            margin-right: 5px;
        }
    } 

    .accordion-element {
    width: 100%;

    .accordion-item:not(:first-child){
        margin-top: -25px;
        }
        .accordion-item{
        padding: 16px;

        }
    }
    @media (max-width:768px){
        h1{
        font-size: 2rem;
        margin-bottom: 10px;
    }
    }
    @media (max-width:425px){
        .download-container{

            display: inline-flex;
            flex-direction: column;
        }
    }

`