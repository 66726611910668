import React, { useState } from "react";
import { NewsStyle } from "./News.styled";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { BsDownload } from "react-icons/bs";
import { getNewsApi } from "../../service/News";
import formatDate from "../../utils/useFormatDate";

export const News = () => {
  const [openNewsId, setOpenNewsId] = useState(null);
  const { data } = getNewsApi.useGetAllNewsDataQuery();
  const { t } = useTranslation();

  const handleOpenModal = (newsId) => {
    setOpenNewsId(newsId);
  };

  const handleCloseModal = () => {
    setOpenNewsId(null);
  };

  const renderNews = () => {
    return data?.map((news) => (
      <div key={news.id} className="newsItemMain">
        <div onClick={() => handleOpenModal(news.id)} className="newsImageItem">
          <img src={news.image} alt={news.title} />
        </div>
        <div className="newsItemBottom">
          <p>{news.title}</p>
          <div>{news.date_created_str}</div>
          <Link to="/news">
            <button>{t("news3")}</button>
          </Link>
        </div>
      </div>
    ));
  };
  const renderModal = () => {
    if (!openNewsId) return null;

    const selectedNews = data?.find((news) => news.id === openNewsId);
    if (!selectedNews) return null;

    return (
      <div className={`modalItem active show`}>
        <div className="modalContent">
          <div onClick={handleCloseModal} className="modal-cross">
            <RxCross2 fill="#fff" size="30px" />
          </div>
          <img src={selectedNews.image} alt={selectedNews.title} />
          <div className="downloadBtn">
            <a href={selectedNews.image} download>
              <BsDownload fill="#fff" size="30px" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <NewsStyle>
      <div className="newsMain">
        <h1>{t("news")}</h1>
        <div className="newsImage">
          {renderNews()}
          {renderModal()}
        </div>
      </div>
    </NewsStyle>
  );
};
