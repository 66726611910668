import React from 'react'
import { ContactsContent } from '../ContactsComponents/ContactsContent'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { Container } from '../styles/Container.styled'

export const Contacts = () => {
  return (
    <>
    <Header/>
    <Container>
        <ContactsContent/>
    </Container>
    <Footer/>
    </>
  )
}
