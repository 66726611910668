import React from 'react'
import { HeaderMain } from './styles/Header.styled'
import { NavLink, Link } from 'react-router-dom'
import {RxCross2} from 'react-icons/rx'
import {GiHamburgerMenu} from 'react-icons/gi'
import logo from '../img/logo.png'
import { useTranslation } from 'react-i18next'
import '../utils/i18next'



export const Header = () => {
    const [click, setClick] = React.useState(false);
    const handleClick = () => setClick(!click);
    
    const {t, i18n} = useTranslation();
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }
    // const [language, setLanguage] = React.useState("tm");

    // const handleLangChange = (e) => {
    //     const lang = e.target.value;
    //     setLanguage(lang);
    //     i18n.changeLanguage(lang); 
    // };
    const linkStyle = {
        padding: "0px",
        backgroundColor: "#fff"
      };
  return (
    <HeaderMain>
        <div className='headerContainer'>
            <div className='headerMain'>
                <div className="headerItems">
                        <div className='logo'>
                            <Link to='/' style={linkStyle}>
                            <img src={logo} alt="logo" />
                            </Link>
                        </div>                    
                    <div className="nav-icon" onClick={handleClick}>
                        {
                            click ? <RxCross2 size='30px'/>  : <GiHamburgerMenu size='30px'/>
                        }
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <NavLink to='/'
                        className={({isActive}) => (
                            isActive ? 'link active' : 'link'
                        )}
                        > <li>{t("header")}</li></NavLink>
                        <NavLink to='/about'><li>{t("header2")}</li></NavLink>
                        <NavLink to='/news'><li>{t("header3")}</li></NavLink>
                        <NavLink to='/service'><li>{t("header4")}</li></NavLink>
                        <NavLink to='/contacts'><li>{t("header5")}</li></NavLink>
                        <div className="dropdown">
                            <button className="dropbtn">{t("lang")}</button>
                            <div className="dropdown-content">
                                <span onClick={()=> changeLanguage("tm")}>{t("lang1")}</span>
                                <span onClick={()=> changeLanguage("ru")}>{t("lang2")}</span>
                                <span onClick={()=> changeLanguage("en")}>{t("lang3")}</span>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        <hr/>
    </HeaderMain>
  )
}
