import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const getServiceDocsApi = createApi({
    reducerPath: "getServiceDocsApi",
    baseQuery: fetchBaseQuery({baseUrl: process.env.REACT_APP_BASE_URL}),
    tagTypes: ["docs"],
    endpoints: (build) => ({
        getServiceFirstDocsData: build.query({
            query: () => ({
                url: "services/first_files/"
            }),
            providesTags: ["docs"]
        }),
        getServiceSecondDocsData: build.query({
            query: () => ({
                url: "services/second_files/"
            }),
            providesTags: ["docs"]
        }),
    })
})