import React from 'react'
import { useTranslation } from 'react-i18next'
import { AboutStyles } from './AboutContent.styled'

export const AboutContent = () => {
  const {t} = useTranslation()
  return (
    <AboutStyles>
        <h1>{t("about")}</h1>
        <div>
            <p> {t("about2")} <br/>
            {t("about3")}<br/>
            {t("about4")} <br/>
            <span>{t("about5")}</span> <br/>
            {t("about6")}<br/>
            <span>{t("about7")}</span> <br/>
            {t("about8")} <br/>
            {t("about9")} <br/>
            {t("about10")} <br/>
            {t("about11")}<br/>
            <span>{t("about12")}</span> </p>
        </div>
    </AboutStyles>
  )
}
