import styled from "styled-components";

export const NewsStyle = styled.div`
    margin-top: 100px;

    h1{
        font-weight: 500;
        font-size: 2.625rem;
        color: #339966;
        margin-bottom: 30px;
    }
    .newsItemMain{
        transition: all 0.2s ease-in;
        &:hover{
            box-shadow: 0px 10px 20px -6px rgba(0,0,0,0.75);
        }
    }

    .newsImage{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        flex-wrap: wrap;


        .newsImageItem img{
            cursor: pointer;
            width: 400px;
        }
        .newsImageItem:not(:last-child) {
            margin-right: 20px;
        }

        .newsItemBottom{
            background-color: #f9f9f9;
            padding: 50px;

            p{
                font-size: 1.25rem;
                font-weight: 600;
                margin-bottom: 15px;
                letter-spacing: 1px;
            }
            button{
                background-color: #6EAB3D;
                color: #fff;
                padding: 15px 30px;
                border-radius: 5px;
                font-size: 1.1rem;
                margin-top: 10px;
            }
        }
    }
    .modalItem{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        }

        .modalContent{
        width: 70%;
        max-width: 1000px;
        padding: 5px 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        /* background-color: rgb(201, 201, 201); */
        transition: all 0.3s ease-in-out;
        border-radius: 8px;
        }

        .modalItem.active{
        opacity: 0;
        visibility: hidden;

        .modalContent{
            opacity: 0;
            visibility: hidden;
            transform: translate(-50%, -30%);
            transition-delay: 0.2s;
        }

        &.show{
            opacity: 1;
            visibility: visible;

            .modalContent{
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%);
            }
        }

    }
    .modal-cross{
        display: flex;
        justify-content: end;
        cursor: pointer;
        margin-bottom: 20px;
        color: #fff;
    }
    .downloadBtn{
        display: flex;
        justify-content: end;
        cursor: pointer;
        margin-top: 5px;
    }
    @media (max-width:1440px){
        .modalContent{
        max-width: 800px;
        }
    }
    @media (max-width:1024px){
        .modalContent{
        max-width: 700px;
        }
    }
    @media (max-width: 425px){
        .modalContent{
        max-width: 300px;
        padding: 0px 0px;
        }
        .modalContent img{
        max-width: 300px;
        min-height: 240px;

        }
    }
    @media (max-width: 375px){
        .modalContent{
        max-width: 300px;
        padding: 0px 0px;
        }
        .modalContent img{
        max-width: 260px;
        min-height: 220px;
        }
        .modal-cross{
        margin-bottom: 10px;

        }
        .downloadBtn{
            margin-top: 5px;
        }
    }
    @media (max-width: 320px){
        .modalContent img{
        max-width: 220px;
        min-height: 180px;
        }
       
    }
    @media (max-width: 425px){
        margin-top: 50px;
        h1{
        font-size: 2rem;
        }
        .newsImage{
            margin-top: 10px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .newsItemMain{
                margin-top: 10px;
            }

            .newsImageItem img{
            width: 200px;
        }
        .newsImageItem:not(:last-child) {
            margin-right: 0px;
        }

        .newsItemBottom{
            width: 250px;
            padding: 20px;

            p{
                width: 200px;
            }
            button{
                padding: 10px 20px;
                border-radius: 5px;
                font-size: 1rem;
            }
        }
        }
        
      
    }
`