import React from 'react'
import { FooterMain } from './styles/Footer.styled'
import { useTranslation } from 'react-i18next'
export const Footer = () => {

  const {t} =useTranslation()
  return (
    <FooterMain>
        <div className="footerContainer">
            <div className="copyRight">
                <p>Copyright {(new Date().getFullYear())}. {t("footer")}</p>
                {/* <p>Powered by <span>Innowasiýa Tilsimaty</span> </p> */}
            </div>
        </div>
    </FooterMain>
  )
}
