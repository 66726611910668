import styled from "styled-components";


export const HeaderMain = styled.header`
    .headerContainer{
        max-width: 1300px;
        margin: 0 auto;
        padding: 0px 10px;
    }

    .headerMain{
        margin: 40px 0px;
    }
    .headerItems{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 10px;
    }

    .nav-menu{
        display: flex;
        align-items: center;  
    }
    .logo{
        margin-bottom: 1rem;
    }

    a{
        border-radius: 2.5px;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 10px;
        color: #6dab3c;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 0.3px;
        transition: all 0.4s;
        &:not(:first-child){
            margin-left: 8px;
        }
        &:hover{
            box-shadow: 0px 10px 20px -6px rgba(0,0,0,0.75);
            background-color: #6dab3c;
            color: #fff;
        }
    }
    hr{
        margin: 40px 0px;
        border: 0;
        height: 1px;
        background: #333;
        background-image: linear-gradient(to right, #ccc, #333, #ccc);
    }
    .link{
        background-color: transparent;
    }
    .active{
        background-color: #6dab3c;
        color: #fff;
    }
    button{
        /* margin-top: -16px; */
        margin-left: 10px;
        padding: 12px;
        color: #fff;
        background-color: #6dab3c;
    }
    .nav-icon{
        display: none;
    }
    .dropbtn {
    margin-left: 15px;
    font-weight: 600;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 10px 15px;
    cursor: pointer;
    letter-spacing: 0.3px;
    background-color: #6dab3c;
    color: #fff;
    transition: all 0.4s;
    border-radius: 2.5px;
    :hover {
    box-shadow: 0px 10px 20px -6px rgba(0,0,0,0.75);
  }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  transition: all 0.4s;
  
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  transition: all 0.4s;
  border-radius: 3.5px;
}

/* Links inside the dropdown */
.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content span:hover {background-color: #6dab3c}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

    @media (max-width: 768px){
        .headerMain{
            margin: 0px 0px
        }
        hr{
        margin: 20px 0px;
        }
        .headerItems{
        margin-top: 10px;
        }

        .nav-menu{
            flex-direction: column;
            width: 100%;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 60px;
            right: -100%;
            opacity: 1;
            transition: all .5s ease;
            overflow-x: hidden;
            margin-top: 10px;
        }
        
        .nav-menu.active{
            background: rgba(0, 0, 0, 0.8);
            right: 0%;
            opacity: 1;
            transition: all .5s ease;
            z-index: 1;
            width: 80%;
            overflow-x: hidden;
        }

        .nav-icon{
            display: block;
            position: absolute;
            top: 10px;
            right: 30px;
            cursor: pointer;
        }
        a{
            color: #fff;
            margin-top: 15px;
            &:not(:first-child){
                margin-left: 0px;
            }
        }
        .dropdown{
            margin-left: 0px;
            margin-top: 20px;
        }

    
    }
    
    @media (max-width: 425px){
        .hidden-text{
            display: block;
            margin-top: 10px;
            p{
                font-size: 1.5rem;
                font-weight: 30px;
                color: #6dab3c;
            }
        }
    }
`