import styled from 'styled-components'


export const ContactsStyles = styled.section`

    .contactContainer{
        display: flex;
        margin-top: 80px;
    }
    h1{
        font-size: 3.5rem;
        letter-spacing: 5px;
        color: #0045A0;
        margin-bottom: 20px;
    }
    .numberItemColoum{
        display: flex;
        align-items: baseline;
    }
    .contactNumber{
        margin-top: 20px;
    }



    .icon{
        display: inline-block;
        padding: 23px;
        background-color: #0d8da4;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        &:hover{
            background-color: rgba(140, 206, 145, 1);
            box-shadow: 0 0 10px #000;
        }
    }
    .numberText{
        margin-left: 20px;
    }
    .numberText span{
        display: block;

        &:first-child{
            font-size: 1.2rem;
            color: grey;
        }
        &:last-child{
            font-size: 1.2rem;
            color: #000;
            font-weight:00;
            margin-top: 5px;
            font-weight: 600;
            max-width: 250px;
            line-height: 25px;
        }
    }
    .inputItem{
        margin-left: 50px;
    }
    .contactsInputMain{
        border-left: 1px solid rgba(0, 0, 0, 0.5);
        width: 50%;
        margin-left: 100px;
    }
    label{
        display: block;
        font-size: 1.3rem;
        color: gray;
        margin-bottom: 10px;
    }
    input{
        border: 1px solid rgba(0, 0, 0, 0.5);
        padding: 15px;
        width: 108%;
        border-radius: 2.5px;
    }
    .flexInput{
        display: flex;
        margin-bottom: 30px;
        input{
            width: 300px;
            
        }
    }
    @media (max-width: 1200px){
        .flexInput{
        display: flex;
        input{
            width: 200px;
        }
        }
        .flexInput div:not(:first-child) {
        margin-left: 42px;
        }
    }
    .flexInput div:not(:first-child){
        margin-left: 77px;

    }
    .inputFull{
        width: 100%;
        margin-bottom: 30px;
    }
    select{
        width: 108%;
        padding: 20px 0px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        font-size: 1.2rem;
        color: grey;
        border-radius: 2.5px;
    }
    textarea{
        width: 108%;
        max-width: 900px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 2.5px;
    }
    .inputBtn button{
        background-color: #6dab3c;
        padding: 10px 20px;
        color: #fff;
        font-size: 1.2rem;
        border-radius: 2.5px;

    }
    @media (max-width:1024px){
        h1{
        font-size: 2rem;
        margin-bottom: 15px;
        }
        .contactContainer{
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .contactsInputMain {
            border-left: none;
            width: 60%;
            margin-left: 0px;
            margin-top: 80px;
        }
        .inputItem{
            margin-left: 0px;
        }
        .flexInput{
            display: block;

            input{
                width: 100%;
            }
        }
        .flexInput div:not(:first-child) {
            margin-top: 15px;
            margin-left: 0px;
        }
        input{
            width: 100%;
        }
        select{
            width: 100%;
        }
        textarea{
            width: 100%;
        }

    }

`