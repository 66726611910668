import styled from "styled-components";
import img from '../../img/section.png';

export const ContactsStyle = styled.div`
    
    background-image: url(${img});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


    .contactsItems{
        display: flex;
        justify-content: space-between;
        transform: translateY(100%);
        animation: revealcontact 1s ease-in forwards 0.5s;
        opacity : 0;
      
    }
    @keyframes revealcontact{
    from{
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}


    .timeItem{
        display: flex;
        align-items: center;
        border: 2px solid #6dab3c;
        border-radius: 8px;
        height: 118px;


        span{
            display: block;
            
        }
    }
    .contactsIcon{
        background-color: #6dab3c;
        padding: 42px 15px;
    }

    .timeList{
        padding: 0px 20px;
        width: 300px;
        text-align: center;
        
        
        .timeTitle{
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 10px;
            color: #7A7A7A;
        }
    }
    span{
            font-size: 1.2rem;
            color: #7A7A7A;
        }
    
        @media (max-width: 1024px){
            .timeList{
                padding: 0px 20px;
                width: 200px;

                .timeTitle{
                font-size: 1.3rem;
                }
            }
            span{
            font-size: 1rem;
            color: #7A7A7A;
        }
        }
        @media (max-width: 768px){
            .contactsItems{
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .timeItem{
                margin-top: 30px;
            }
        }
        

` 