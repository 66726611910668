import React from 'react'
import { ServiceStyle } from './Service.styled'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const Service = () => {
    const {t} = useTranslation()
  return (
    <ServiceStyle>
        <div className='ServiceMain'>
            <div className='ServiceTitleMain'>
                <div className='Title'>
                    {t("service")}
                </div>
                <Link to='/service'>
                    <div className='TitleLink'>
                    {t("service2")}
                    </div>
                </Link>
               
            </div>
            <div>
                <div className="serviceTextContainer">
                    <div className="serviceTextItem">
                        <Link to='/service'>
                        <p>{t("service3")}</p>
                        </Link>
                    </div>
                    <div className="serviceTextItem">
                        <Link to='/service' >
                        <p>{t("service4")}</p>
                        </Link>
                        
                    </div>
                    <div className="serviceTextItem">
                        <Link to='/service'>
                        <p>{t("service5")}</p>
                        </Link>
                        
                    </div>
                </div>
            </div>
        </div>
    </ServiceStyle>
  )
}
