import React from 'react'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { ServiceContent } from '../ServiceComponents/ServiceContent'
import { Container } from '../styles/Container.styled'

export const Service = () => {
  return (
    <>
        <Header/>
        <Container>
            <ServiceContent/>
        </Container>
        <Footer/>
    </>
  )
}
