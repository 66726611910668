import { configureStore } from "@reduxjs/toolkit";
import { getNewsApi } from "../service/News";
import { getServiceDocsApi } from "../service/serviceDocs";

const store = configureStore({
  reducer: {
    [getNewsApi.reducerPath]: getNewsApi.reducer,
    [getServiceDocsApi.reducerPath]: getServiceDocsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      getNewsApi.middleware,
      getServiceDocsApi.middleware,
    ),
});

export default store;
