import {Container} from '../styles/Container.styled'
import {Header} from '../Header'
import {Hero} from '../HomeCompotents/Hero'
import {Contacts} from '../HomeCompotents/Contacts'
import {Service} from '../HomeCompotents/Service'
import {News} from '../HomeCompotents/News'
import {Footer} from '../Footer'
export const Home = () => {
  return (
    <>
    <Header/>
    <Container> 
        <Hero/>
        <Contacts/>
        <Service/>
        <News/>
    </Container>
    <Footer/>
    </>
  )
}
