import styled from "styled-components";


export const ServiceStyle = styled.div`
    .ServiceMain{
        margin-top: 100px;
    }

    .ServiceTitleMain{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }

    .Title{
        font-weight: 500;
        font-size: 2.625rem;
        color: #339966;
    }
    .TitleLink{
        font-size: 1.25rem;
        color: #339966;
        cursor: pointer;
        position: relative;
        border: none;
        background: none;
        transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
        transition-duration: 400ms;
        transition-property: color;
    }
    .TitleLink:focus,
    .TitleLink:hover {
    color: #339966;
    }

    .TitleLink:focus:after,
    .TitleLink:hover:after {
    width: 100%;
    left: 0%;
    }

    .TitleLink:after {
    content: "";
    pointer-events: none;
    bottom: -7px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: #339966;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
    }
    .serviceTextContainer{
        display: flex;
    }
    .serviceTextItem{
        border-radius: 8px;
        border: 4px solid #AFDD82;
        background-color: #6DAB3C;
        padding: 35px 15px;
        transition: all 0.2s ease-in;
        cursor: pointer;
        &:not(:last-child){
            margin-right: 40px;
        }
        p{
            font-size: 1.25rem;
            font-weight: 600;
            color: #fff;
            line-height: 42px;
            text-align: center;
            letter-spacing: 1px;
        }
        &:hover{
            box-shadow: 0px 10px 20px -6px rgba(0,0,0,0.75);
        }
    }
    @media (max-width: 1024px){
        .serviceTextItem{
        padding: 15px 10px;
        &:not(:last-child){
            margin-right: 30px;
        }
        p{
            line-height: 32px;

        }
        }
    }
    @media (max-width: 768px){
        
        .serviceTextItem{
        padding: 15px 10px;
        &:not(:last-child){
            margin-right: 30px;
        }
        p{
            font-size: 1rem;
            line-height: 25px;

        }
        }
    }
    @media (max-width:425px){
        .serviceTextContainer{
            flex-direction: column;
        }
        .serviceTextItem{
        padding: 15px 10px;
        margin-top: 20px;
        &:not(:last-child){
            margin-right: 0px;
            
        }
        p{
            font-size: 1rem;
            line-height: 25px;

        }
        }
        .Title{
        font-size: 2rem;
        }
        .TitleLink{
        font-size: 1rem;
        }
        
    }


`