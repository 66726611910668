import React from "react";
import { ServiceStyles } from "./ServiceContent.styled";
import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion } from "react-bootstrap";
import "./accordion.css";
import { useTranslation } from "react-i18next";
import { BsDownload } from "react-icons/bs";
import miTM from "../Download/mi_tm.docx";
import miRU from "../Download/mi_ru.docx";
import miEN from "../Download/mi_en.docx";
import { getServiceDocsApi } from "../../service/serviceDocs";

export const ServiceContent = () => {
  const { data: firstDocs } =
    getServiceDocsApi.useGetServiceFirstDocsDataQuery();
  const { data: secondDocs } =
    getServiceDocsApi.useGetServiceSecondDocsDataQuery();
  const { t } = useTranslation();

  return (
    <ServiceStyles>
      <div className="serviceMain">
        <h1>{t("serviceMain")}</h1>
        <div className="serviceAccordion">
          <div className="accordion-element">
            <Accordion className="mt-5 p-3">
              <Accordion.Item eventKey="0" className="item">
                <Accordion.Header>{t("ser1")}</Accordion.Header>
                <Accordion.Body
                  style={{ fontSize: "1.2rem", lineHeight: "35px" }}
                >
                  {t("ser1-1")} <br />
                  {t("ser1-2")}
                  <br />
                  {t("ser1-3")}
                  <br />
                  {t("ser1-4")}
                  <br />
                  {t("ser1-5")}
                  <br />
                  {t("ser1-6")}
                  <br />
                  {t("ser1-7")}
                  <br />
                  {t("ser1-8")}
                  <br />
                  {t("ser1-9")}
                  <br />
                  {t("ser1-10")}
                  <br />
                  {t("ser1-11")}
                  <br />
                  {t("ser1-12")}
                  <br />
                  {t("ser1-13")}
                  <br />
                  {t("ser1-14")}
                  <br />
                  {t("ser1-15")}
                  <br />
                  {t("ser1-16")}
                  <br />
                  {t("ser1-17")}
                  <br />
                  {t("ser1-18")}
                  <br />
                  {t("ser1-19")}
                  <br />
                  {t("ser1-20")}
                  <br />
                  {t("ser1-21")}
                  <br />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="item">
                <Accordion.Header>{t("ser2")}</Accordion.Header>
                <Accordion.Body
                  style={{ fontSize: "1.2rem", lineHeight: "35px" }}
                >
                  {t("ser2-2")}
                  <br />
                  {t("ser2-4")}
                  <br />
                  {t("ser2-5")}
                  <br />
                  {t("ser2-6")}
                  <br />
                  {t("ser2-7")}
                  <br />
                  {t("ser2-8")}
                  <br />
                  {t("ser2-9")}
                  <br />
                  {t("ser2-10")}
                  <br />
                  {t("ser2-11")}
                  <br />
                  {t("ser2-12")}
                  <br />
                  {t("ser2-13")}
                  <br />
                  {t("ser2-14")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="item">
                <Accordion.Header>{t("ser3")}</Accordion.Header>
                <Accordion.Body
                  style={{ fontSize: "1.2rem", lineHeight: "35px" }}
                >
                  {t("ser3-1")}
                  <br />
                  {t("ser3-2")}
                  <br />
                  {t("ser3-3")}
                  <br />
                  {t("ser3-4")}
                  <br />
                  {t("ser3-5")}
                  <br />
                  {t("ser3-6")}
                  <br />
                  {t("ser3-7")}
                  <br />
                  {t("ser3-8")}
                  <br />
                  {t("ser3-9")}
                  <br />
                  {t("ser3-10")}
                  <br />
                  {t("ser3-11")}
                  <br />
                  {t("ser3-12")}
                  <br />
                  {t("ser3-13")}
                  <br />
                  {t("ser3-14")}
                  <br />
                  {t("ser3-15")}
                  <br />
                  {t("ser3-16")}
                  <br />
                  {t("ser3-17")}
                  <br />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="item">
                <Accordion.Header>{t("ser4")}</Accordion.Header>
                <Accordion.Body
                  style={{ fontSize: "1.2rem", lineHeight: "35px" }}
                >
                  {t("ser4-1")}
                  <br />
                  {t("ser4-2")}
                  <br />
                  {t("ser4-3")}
                  <br />
                  {t("ser4-4")}
                  <br />
                  {t("ser4-5")}
                  <br />
                  {t("ser4-6")}
                  <br />
                  {t("ser4-7")}
                  <br />
                  {t("ser4-8")}
                  <br />
                  {t("ser4-9")}
                  <br />
                  {t("ser4-10")}
                  <br />
                  {t("ser4-11")}
                  <br />
                  {t("ser4-12")}
                  <br />
                  {t("ser4-13")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="item">
                <Accordion.Header>{t("ser5")}</Accordion.Header>
                <Accordion.Body
                  style={{ fontSize: "1.2rem", lineHeight: "35px" }}
                >
                  {t("ser5-1")}
                  <br />
                  {t("ser5-2")}
                  <br />
                  {t("ser5-3")}
                  <br />
                  {t("ser5-4")}
                  <br />
                  {t("ser5-5")}
                  <br />
                  {t("ser5-6")}
                  <br />
                  {t("ser5-7")}
                  <br />
                  {t("ser5-8")}
                  <br />
                  {t("ser5-9")}
                  <br />
                  {t("ser5-10")}
                  <br />
                  {t("ser5-11")}
                  <br />
                  {t("ser5-12")}
                  <br />
                  {t("ser5-13")}
                  <br />
                  {t("ser5-14")}
                  <br />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6" className="item">
                <Accordion.Header>{t("ser6")}</Accordion.Header>
                <Accordion.Body
                  style={{ fontSize: "1.2rem", lineHeight: "35px" }}
                >
                  {t("ser6-1")}
                  <br />
                  {t("ser6-2")}
                  <br />
                  {t("ser6-3")}
                  <br />
                  {t("ser6-4")}
                  <br />
                  {t("ser6-5")}
                  <br />
                  {t("ser6-6")}
                  <br />
                  {t("ser6-7")}
                  <br />
                  {t("ser6-8")}
                  <br />
                  {t("ser6-9")}
                  <br />
                  {t("ser6-10")}
                  <br />
                  {t("ser6-11")}
                  <br />
                  {t("ser6-12")}
                  <br />
                  {t("ser6-13")}
                  <br />
                  {t("ser6-14")}
                  <br />
                  {t("ser6-15")}
                  <br />
                  {t("ser6-16")}
                  <br />
                  {t("ser6-17")}
                  <br />
                  {t("ser6-18")}
                  <br />
                  {t("ser6-19")}
                  <br />
                  {t("ser6-20")}
                  <br />
                  {t("ser6-21")}
                  <br />
                  {t("ser6-22")}
                  <br />
                  {t("ser6-23")}
                  <br />
                  {t("ser6-24")}
                  <br />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7" className="item">
                <Accordion.Header>{t("ser7")}</Accordion.Header>
                <Accordion.Body
                  style={{ fontSize: "1.2rem", lineHeight: "35px" }}
                >
                  {t("ser7-1")}
                  <br />
                  <strong>{t("ser7-2")}</strong>
                  <br />
                  {firstDocs?.map((firstFile) => (
                    <div key={firstFile.id} className="download-container">
                      <a
                        className="download-btn"
                        href={firstFile?.file_tk}
                        download
                      >
                        <span>{t("lang1")}</span>
                        <BsDownload fill="#fff" size="20px" />
                      </a>
                      <a
                        className="download-btn"
                        href={firstFile?.file_ru}
                        download
                      >
                        <span>{t("lang2")}</span>
                        <BsDownload fill="#fff" size="20px" />
                      </a>
                      <a
                        className="download-btn"
                        href={firstFile?.file_en}
                        download
                      >
                        <span>{t("lang3")}</span>
                        <BsDownload fill="#fff" size="20px" />
                      </a>
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8" className="item">
                <Accordion.Header>{t("ser8")}</Accordion.Header>
                <Accordion.Body
                  style={{ fontSize: "1.2rem", lineHeight: "35px" }}
                >
                  {t("ser8-1")}
                  <br />
                  <strong>{t("ser8-2")}</strong> <br />
                  {secondDocs?.map((secondFile) => (
                    <div key={secondFile.id} className="download-container">
                      <a
                        className="download-btn"
                        href={secondFile.file_tk}
                        download
                      >
                        <span>{t("lang1")}</span>
                        <BsDownload fill="#fff" size="20px" />
                      </a>
                      <a className="download-btn"  href={secondFile.file_ru} download>
                        <span>{t("lang2")}</span>
                        <BsDownload fill="#fff" size="20px" />
                      </a>
                      <a className="download-btn"  href={secondFile.file_en} download>
                        <span>{t("lang3")}</span>
                        <BsDownload fill="#fff" size="20px" />
                      </a>
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </ServiceStyles>
  );
};
