import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const getNewsApi = createApi({
    reducerPath: "getNewsApi",
    baseQuery: fetchBaseQuery({baseUrl: process.env.REACT_APP_BASE_URL}),
    tagTypes: ["news"],
    endpoints: (build) => ({
        getAllNewsData: build.query({
            query: () => ({
                url: "news/news"
            }),
            providesTags: ["news"]
        })
    })
})